import { DefaultTheme, ThemeProvider } from 'styled-components';

interface SharedThemeProviderProps {
  children: React.ReactNode;
  theme: DefaultTheme;
}

export const SharedThemeProvider = ({
  children,
  theme,
}: SharedThemeProviderProps) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
