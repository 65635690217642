import { SWRConfig } from 'swr';
import { FullConfiguration } from 'swr/_internal';

interface SWRProviderProps {
  config?: Partial<FullConfiguration>;
  children?: React.ReactNode;
}

export const SWRProvider: React.FC<SWRProviderProps> = ({
  children,
  config,
}) => {
  const swrConfig = {
    ...config,
    fetcher: (input: RequestInfo, init?: RequestInit) =>
      fetch(input, init).then((response) => response.json()),
  };
  return <SWRConfig value={swrConfig}>{children}</SWRConfig>;
};
